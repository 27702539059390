













import { PostVisibilityLevel } from '@kessel/core'
import { computed, defineComponent, PropType, useContext } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    level: {
      type: Number as PropType<PostVisibilityLevel>,
      default: -1,
    },
    isLocked: { type: Boolean, default: false },
  },

  setup(props) {
    const { i18n } = useContext()

    const label = computed(() => {
      if (props.level === PostVisibilityLevel.PUBLIC) {
        return i18n.t('FREE_ACCESS')
      } else if (props.level === PostVisibilityLevel.SUBSCRIBERS) {
        return i18n.t('ONLY_FOR_SUBSCRIBERS')
      }

      return i18n.t('ONLY_FOR_PAID_SUBSCRIBERS')
    })

    const getColor = computed(() => {
      const levels = {
        [PostVisibilityLevel.PUBLIC]: 'primary',
        [PostVisibilityLevel.SUBSCRIBERS]: 'normal',
        [PostVisibilityLevel.PAID_SUBSCRIBERS]: 'primary',
      }
      return levels[props.level]
    })

    const getIcon = computed(() => {
      const levels = {
        [PostVisibilityLevel.PUBLIC]: undefined,
        [PostVisibilityLevel.SUBSCRIBERS]: 'lock',
        [PostVisibilityLevel.PAID_SUBSCRIBERS]: 'lock',
      }
      return levels[props.level]
    })

    return {
      label,
      getColor,
      getIcon,
    }
  },
})
