




























import { Post } from '@kessel/core'
import { PropType, defineComponent, toRefs, useContext, useRouter } from '@nuxtjs/composition-api'

export default defineComponent({
  props: {
    post: { type: Object as PropType<Post>, required: true },
    date: { type: String, default: '' },
    readingTime: { type: Number, default: 0 },
    likes: { type: Number, default: null },
    scheduled: { type: String, default: null },
  },
  setup(props) {
    const router = useRouter()
    const { i18n } = useContext()
    const { scheduled, post } = toRefs(props)
    const dateScheduled = `${i18n.t('SCHEDULED_POST')} ${i18n.d(new Date(scheduled.value), 'long')}`
    const toPostEdit = () => {
      if (scheduled.value) {
        router.push(`/publication/${post.value.publication_id}/write?post=${post.value.id}&edit=true`)
      }
    }

    return {
      toPostEdit,
      dateScheduled,
    }
  },
})
