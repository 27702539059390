
















































import { Post } from '@kessel/core'
import { computed, defineComponent, PropType, toRefs } from '@nuxtjs/composition-api'
import { useDomain } from '~/stores/domain'

export default defineComponent({
  props: {
    item: { type: Object as PropType<Post>, required: true },
    isBig: { type: Boolean, default: false },
  },
  setup(props) {
    const { item } = toRefs(props)
    const { relativePathToPost } = useDomain()

    return {
      relativePathToPost: computed(() => relativePathToPost(item.value)),
    }
  },
})
